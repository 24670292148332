* {
  font-family: 'Montserrat', sans-serif!important;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Montserrat', sans-serif!important;
}

#root {
  height: 100%
}